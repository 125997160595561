export const FormBuilderTemplates = {
    builderComponentFormTemplate(builderComponentForm, ctx) {
        document.querySelector('.details-area').addEventListener('click', evt => {
            if ((evt.target as HTMLElement).parentElement?.classList.contains('component-settings-button-copy')) {
                (evt.target as HTMLElement).parentElement.click();
                ((evt.target as HTMLElement).parentElement.parentElement.querySelector('.component-settings-button-paste') as HTMLElement).click();
            }

            if ((evt.target as HTMLButtonElement).type != 'button') {
                evt.stopPropagation();
                evt.stopImmediatePropagation();
            }
        });

        let defaultBuilderComponentForm = builderComponentForm(ctx);
        let div = document.createElement('div');
        div.innerHTML = defaultBuilderComponentForm;

        div.querySelectorAll('.builder-component').forEach(x => {
            if (x.querySelector('.formio-component-button') != null) {
                x.querySelectorAll('.component-btn-group .component-settings-button-remove').forEach(y => y.remove());
            }
        });

        div.querySelectorAll('.component-settings-button-edit-json').forEach(x => x.remove());
        div.querySelectorAll('.component-settings-button-move').forEach(x => x.remove());
        div.querySelectorAll('.component-settings-button-paste').forEach(x => (x as HTMLElement).style.setProperty('display', 'none', 'important'));

        let finalBuilderComponentForm = div.innerHTML;

        finalBuilderComponentForm = finalBuilderComponentForm
            .replace(/"fa fa-arrows"/gi, '"fa fa-arrows-alt"')
            .replace(/"fa fa-times-circle-o"/gi, '"fa fa-times-circle"');

        return finalBuilderComponentForm;
    },

    builderEditFormTemplate(builderEditForm, ctx) {
        let tempHtml = builderEditForm(ctx);
        if (tempHtml.includes(' formio-component-inputMask ')) {
            let div = document.createElement('div');
            div.innerHTML = tempHtml;
            //div.querySelector('.formio-component-inputMask [ref="tooltip"]').attributes['data-tooltip'].value = `
            //    Máscara que será aplicada no campo para garantir que o mesmo tenha um valor predefinido. Use :

            //    - "9", para indicar a entrada de um dígito;
            //    - "a", para indicar o uso de um caracter minúsculo;
            //    - "A", para indicar o uso de um caracter maiúsculo ou minúsculo;
            //    - "*", para indicar o uso de um caracter alfanumérico qualquer.
            //`;
            div.querySelector('.formio-component-inputMask [ref="tooltip"]').attributes['data-tooltip'].value = `
                    Máscara que será aplicada no campo para garantir que o mesmo tenha um valor predefinido.
                    Use "9" para indicar a entrada de um dígito e "A" para um caracter. Exemplo:
                    "(999) AAA-999"
                `;
            tempHtml = div.innerHTML;
        }

        return tempHtml.replace(/"fa fa-times-circle-o"/gi, '"fa fa-times-circle"');
    },

    datagridFormTemplate(datagridForm, ctx) {
        return datagridForm(ctx)
            .replace(/"fa fa-times-circle-o"/gi, '"fa fa-times-circle"')
            .replace(/table-bordered/gi, '')
            .replace(/(button.*btn) btn-secondary (formio-button-remove-row)/gi, '$1 btn-link $2');
    }
}
